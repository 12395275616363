import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useFilters } from 'react-table';
import { getMenuItemsByRestaurant, deleteMenuItem } from '../services/menuService';
import MenuItemForm from './MenuItemForm';
import config from '../config';

const MenuItemList = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [filter, setFilter] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [message, setMessage] = useState('');

    const fetchData = async () => {
        try {
            const restaurantId = localStorage.getItem('restaurantId');
            const items = await getMenuItemsByRestaurant(restaurantId);
            setMenuItems(items);
            setFilteredItems(items);
        } catch (error) {
            console.error('Errore nel caricamento degli articoli del menu:', error);
            setMessage({ text: 'Errore nel caricamento dei dati.', type: 'error' });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFilterChange = (e) => {
        const value = e.target.value;
        setFilter(value);
        const filteredData = menuItems.filter(item =>
            item.attributes.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredItems(filteredData);
    };

    const handleEdit = (item) => {
        setEditingItem(item);
        setShowForm(true);
    };

    const handleDelete = async (item) => {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo articolo?");
        if (confirmDelete) {
            try {
                await deleteMenuItem(item.id);
                setMessage({ text: 'Articolo eliminato con successo!', type: 'success' });
                fetchData(); // Aggiorna la lista degli articoli
            } catch (error) {
                setMessage({ text: 'Errore durante l\'eliminazione dell\'articolo.', type: 'error' });
            }
        }
    };

    const columns = useMemo(() => [
        {
            Header: 'Immagine',
            accessor: 'attributes.image',
            Cell: ({ cell }) => {
                const imageUrl = cell.value?.data?.attributes?.url;
                const correctedImageUrl = imageUrl ? imageUrl.replace('/api', '') : null;
                return correctedImageUrl ? (
                    <img src={`${config.API_URL.replace('/api', '')}${correctedImageUrl}`} alt="Immagine" style={{ width: '50px', height: '50px' }} />
                ) : (
                    'N/A'
                );
            },
        },
        { Header: 'Nome', accessor: 'attributes.name' },
        { Header: 'Descrizione', accessor: 'attributes.description' },
        { Header: 'Prezzo', accessor: 'attributes.price' },
        { Header: 'Categoria', accessor: 'attributes.menu_category.data.attributes.name' },
        {
            Header: 'Azione',
            Cell: ({ row }) => (
                <div className="d-flex justify-content-start">
                    <button className="btn btn-primary btn-sm mr-2" onClick={() => handleEdit(row.original)}>
                        Modifica
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row.original)}>
                        Elimina
                    </button>
                </div>
            ),
        },
    ], []);

    const data = useMemo(() => filteredItems || [], [filteredItems]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useFilters);

    return (
        <div className="container">
            <h2 className="mb-4">Gestione Menu</h2>
            {message && (
                <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
                    {message.text}
                </div>
            )}
            {showForm ? (
                <MenuItemForm
                    item={editingItem}
                    onSave={() => {
                        setShowForm(false);
                        setEditingItem(null);
                        setMessage({ text: 'Salvataggio avvenuto con successo!', type: 'success' });
                        fetchData();
                    }}
                    onError={() => {
                        setMessage({ text: 'Errore durante il salvataggio.', type: 'error' });
                    }}
                    onCancel={() => {
                        setShowForm(false);
                        setEditingItem(null);
                    }}
                />
            ) : (
                <>
                    <input
                        type="text"
                        placeholder="Filtra per nome"
                        value={filter}
                        onChange={handleFilterChange}
                        className="form-control mb-3"
                    />
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table table-striped">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th key={column.id} {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.length > 0 ? (
                                    rows.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr key={row.id} {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td key={cell.id} {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="5">Nessun articolo trovato.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <button
                        type="button"
                        className="btn btn-success mt-4"
                        onClick={() => setShowForm(true)}
                    >
                        Crea Nuovo Articolo
                    </button>
                </>
            )}
        </div>
    );
};

export default MenuItemList;

