import axios from 'axios';
import config from '../config';

export const signin = async (userName, password) => {
    try {
        const response = await axios.post(`${config.API_URL}/auth/local`, {
            identifier: userName,
            password: password,
        });

        await localStorage.setItem('jwtToken', response.data.jwt); // Salva il token JWT

        // Effettua una richiesta per ottenere i dettagli dell'utente inclusa la relazione con il ristorante
        const userResponse = await getUserDetails(response.data.jwt);
        const restaurantId = userResponse.restaurant?.id;

        if (restaurantId) {
            await localStorage.setItem('restaurantId', restaurantId);
        } else {
            throw new Error("ID del ristorante non trovato nell'oggetto utente");
        }

        return response;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            throw new Error("Credenziali non valide. Verifica nome utente e password.");
        } else if (error.message === "ID del ristorante non trovato nell'oggetto utente") {
            throw error;
        } else {
            throw new Error("Si è verificato un errore durante il login. Riprova.");
        }
    }
};


export const getUserDetails = async (token) => {
    try {
        const response = await axios.get(`${config.API_URL}/users/me`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            params: {
                populate: 'restaurant', // Assicura che la relazione venga popolata
            },
        });
        return response.data;
    } catch (error) {
        console.error("Errore durante il recupero dei dettagli dell'utente:", error);
        throw error;
    }
};



// Funzione di registrazione
export const signup = async (fullName, email, password) => {
    try {
        const response = await axios.post(`${config.API_URL}/auth/local/register`, {
            username: fullName,
            email: email,
            password: password,
        });

        // Restituisci la risposta della registrazione
        return response;
    } catch (error) {
        // Gestisci gli errori in modo appropriato
        console.error("Errore durante la registrazione:", error);
        throw error;
    }
};

// Funzione per il recupero della password
export const recoverPassword = async (email) => {
    try {
        const response = await axios.post(`${config.API_URL}/auth/forgot-password`, {
            email: email,
        });

        // Restituisci la risposta del recupero password
        return response;
    } catch (error) {
        // Gestisci gli errori in modo appropriato
        console.error("Errore durante il recupero della password:", error);
        throw error;
    }
};

// Funzione di logout
export const logout = async () => {
    try {
        // Rimuovi il token dal localStorage
        await localStorage.removeItem('jwtToken');
        await localStorage.clear(); // Pulisci anche il resto del localStorage se necessario
    } catch (error) {
        // Gestisci gli errori in modo appropriato
        console.error("Errore durante il logout:", error);
        throw error;
    }
};

