import React, { useState, useEffect } from 'react';
import { getOrdersInAttesaConferma, getOrdersInCorso, getOrdersInPreparazione, getOrdersInConsegna, getOrdersPassati } from '../services/orderService';

const DashboardOverview = () => {
  const [orderCounts, setOrderCounts] = useState({
    attesaConferma: 0,
    inCorso: 0,
    inPreparazione: 0,
    inConsegna: 0,
    passati: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderCounts = async () => {
      setLoading(true);
      try {
        const attesaConferma = await getOrdersInAttesaConferma();
        const inCorso = await getOrdersInCorso();
        const inPreparazione = await getOrdersInPreparazione();
        const inConsegna = await getOrdersInConsegna();
        const passati = await getOrdersPassati();

        setOrderCounts({
          attesaConferma: attesaConferma.length,
          inCorso: inCorso.length,
          inPreparazione: inPreparazione.length,
          inConsegna: inConsegna.length,
          passati: passati.length,
        });
      } catch (error) {
        console.error('Errore nel recupero dei contatori degli ordini:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderCounts();
  }, []);

  if (loading) {
    return <p>Caricamento dati in corso...</p>;
  }

  return (
    <div>
      <h2>Dashboard Overview</h2>
      <div className="dashboard-cards">
        <div className="card">
          <h3>Ordini in Attesa di Conferma</h3>
          <p>{orderCounts.attesaConferma}</p>
        </div>
        <div className="card">
          <h3>Ordini in Corso</h3>
          <p>{orderCounts.inCorso}</p>
        </div>
        <div className="card">
          <h3>Ordini in Preparazione</h3>
          <p>{orderCounts.inPreparazione}</p>
        </div>
        <div className="card">
          <h3>Ordini in Consegna</h3>
          <p>{orderCounts.inConsegna}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;

