import React from 'react';

const Header = ({ restaurantName }) => {
  return (
    <div
      className="bg-dark text-white py-2 px-4"
      style={{ marginLeft: '60px' }} // Aggiungi il margine sinistro
    >
      <h5 className="m-0">Pannello Ristoratore: {restaurantName}</h5>
    </div>
  );
};

export default Header;

