import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import RestaurantList from './components/RestaurantList';
import OrderList from './components/OrderList';
import MenuItemList from './components/MenuItemList';
import Settings from './components/Settings'; 
import LoginForm from './components/LoginForm';
import DashboardOverview from './components/DashboardOverview'; 
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route 
          path="/login" 
          element={<PublicRoute><LoginForm /></PublicRoute>} 
        />
        <Route 
          path="/dashboard" 
          element={<PrivateRoute><DashboardLayout /></PrivateRoute>}
        >
          <Route index element={<DashboardOverview />} />

          <Route path="orders" element={<OrderList />} />
          <Route path="menu" element={<MenuItemList />} />
          <Route path="settings" element={<Settings />} /> 
        </Route>
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;

