import axios from 'axios';
import config from '../config';

const getToken = async () => {
    const token = await localStorage.getItem('jwtToken');
    if (!token) {
        throw new Error('Token JWT non trovato');
    }
    return token;
};

export const getCategories = async () => {
    try {
        const token = await getToken();  // Recupera il token JWT dal localStorage

        // Esegui la chiamata API per recuperare tutte le categorie
        const response = await axios.get(`${config.API_URL}/categories`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            params: {
                populate: '*',  // Assicura che tutte le relazioni siano popolate
            },
        });

        return response.data.data;  // Restituisce l'array di categorie
    } catch (error) {
        console.error('Errore nel recupero delle categorie:', error);
        throw error;
    }
};



export const getMenuItemsByRestaurant = async (restaurantId) => {
    try {
        const token = await getToken(); // Usa il token salvato

        const response = await axios.get(`${config.API_URL}/menu-items`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            params: {
                filters: {
                    restaurant: {
                        id: {
                            $eq: restaurantId,
                        },
                    },
                },
                populate: '*',  // Popola le relazioni
            },
        });

        return response.data.data;  // Restituisce gli articoli del menu
    } catch (error) {
        console.error('Errore nel recupero degli articoli del menu:', error);
        throw error;
    }
};

export const createMenuItem = async (menuItemData) => {
    try {
        const token = await getToken();
        const restaurantId = localStorage.getItem('restaurantId');

        if (!restaurantId) {
            throw new Error('ID del ristorante non trovato nel localStorage');
        }

        // Includi l'ID della categoria nei dati
        const dataWithRestaurant = {
            ...menuItemData,
            restaurant: restaurantId,
            menu_category: menuItemData.categoryId, // Aggiungi l'ID della categoria
        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(dataWithRestaurant));
        if (menuItemData.image) {
            formData.append('files.image', menuItemData.image);
        }

        const response = await axios.post(`${config.API_URL}/menu-items`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data.data;
    } catch (error) {
        console.error('Errore durante la creazione dell\'articolo del menu:', error);
        throw error;
    }
};

export const updateMenuItem = async (menuItemId, menuItemData) => {
    try {
        const token = await getToken();
        const restaurantId = localStorage.getItem('restaurantId');

        if (!restaurantId) {
            throw new Error('ID del ristorante non trovato nel localStorage');
        }

        // Includi l'ID della categoria nei dati
        const dataWithRestaurant = {
            ...menuItemData,
            restaurant: restaurantId,
            menu_category: menuItemData.categoryId, // Aggiungi l'ID della categoria
        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(dataWithRestaurant));
        if (menuItemData.image) {
            formData.append('files.image', menuItemData.image);
        }

        const response = await axios.put(`${config.API_URL}/menu-items/${menuItemId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data.data;
    } catch (error) {
        console.error('Errore durante l\'aggiornamento dell\'articolo del menu:', error);
        throw error;
    }
};

export const deleteMenuItem = async (menuItemId) => {
    try {
        const token = await getToken();

        await axios.delete(`${config.API_URL}/menu-items/${menuItemId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Errore durante l\'eliminazione dell\'articolo del menu:', error);
        throw error;
    }
};


