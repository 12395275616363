import React, { useState, useEffect } from 'react';
import { createMenuItem, updateMenuItem, getCategories } from '../services/menuService';

const MenuItemForm = ({ item, onSave, onCancel, onError }) => {
    const [formData, setFormData] = useState({
        name: item?.attributes?.name || '',
        description: item?.attributes?.description || '',
        price: item?.attributes?.price || '',
        categoryId: item?.attributes?.menu_category?.data?.id || '',
        image: null,
    });

    const [categories, setCategories] = useState([]);
    const [message, setMessage] = useState('');
    const [imagePreview, setImagePreview] = useState(item?.attributes?.image?.data?.attributes?.url || '');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const fetchedCategories = await getCategories();
                setCategories(fetchedCategories);
            } catch (error) {
                console.error('Errore nel recupero delle categorie:', error);
                setMessage('Errore nel recupero delle categorie.');
            }
        };

        fetchCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevState) => ({ ...prevState, image: file }));
        
        // Aggiorna l'anteprima dell'immagine
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (item?.id) {
                await updateMenuItem(item.id, formData);
            } else {
                await createMenuItem(formData);
            }
            setMessage('Articolo salvato con successo!');
            onSave();
        } catch (error) {
            setMessage('Errore durante il salvataggio dell\'articolo. Riprova.');
            onError();
            console.error('Errore durante il salvataggio:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label>Nome</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    required
                />
            </div>
            <div className="mb-3">
                <label>Descrizione</label>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label>Prezzo</label>
                <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    className="form-control"
                    required
                />
            </div>
            <div className="mb-3">
                <label>Categoria</label>
                <select
                    name="categoryId"
                    value={formData.categoryId}
                    onChange={handleChange}
                    className="form-control"
                >
                    <option value="">Nessuna categoria</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.attributes.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-3">
                <label>Immagine</label>
                <input type="file" onChange={handleFileChange} className="form-control" />
                {imagePreview && (
                    <div className="mt-3">
                        <img src={imagePreview} alt="Anteprima" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                )}
            </div>
            {message && <div className="alert alert-info">{message}</div>}
            <button type="submit" className="btn btn-success">Salva</button>
            <button type="button" className="btn btn-secondary" onClick={onCancel}>Annulla</button>
        </form>
    );
};

export default MenuItemForm;

