import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { signin } from './authService';
import config from '../config';

export const getShopTypes = async () => {
    try {
        let token = await localStorage.getItem('jwtToken');

        if (!token) {
            throw new Error('Token JWT non trovato');
        }

        const decodedToken = jwtDecode(token);

        if (decodedToken.exp * 1000 < Date.now()) {
            const userName = await localStorage.getItem('userName');
            const password = await localStorage.getItem('password');
            
            if (userName && password) {
                const response = await signin(userName, password);
                token = response.data.jwt;
                await localStorage.setItem('jwtToken', token);
            } else {
                throw new Error('Credenziali non trovate, eseguire nuovamente l\'accesso.');
            }
        }

        const response = await axios.get(`${config.API_URL}/shop-types`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            params: {
                populate: '*',
            },
        });

        // Verifica che la risposta contenga un array di dati
        return response.data.data; // Restituisce solo l'array dei dati
    } catch (error) {
        console.error('Errore durante la richiesta API:', error);
        throw error;
    }
};


export const getZones = async () => {
    try {
        let token = await localStorage.getItem('jwtToken');

        if (!token) {
            throw new Error('Token JWT non trovato');
        }

        const decodedToken = jwtDecode(token);

        if (decodedToken.exp * 1000 < Date.now()) {
            const userName = await localStorage.getItem('userName');
            const password = await localStorage.getItem('password');

            if (userName && password) {
                const response = await signin(userName, password);
                token = response.data.jwt;
                await localStorage.setItem('jwtToken', token);
            } else {
                throw new Error('Credenziali non trovate, eseguire nuovamente l\'accesso.');
            }
        }

        const response = await axios.get(`${config.API_URL}/zones`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data.data; // Restituisce tutte le zone
    } catch (error) {
        console.error('Errore durante la richiesta API:', error);
        throw error;
    }
};

