import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { signin } from './authService';
import config from '../config';

export const getAuthToken = async () => {
    let token = await localStorage.getItem('jwtToken');
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp * 1000 < Date.now()) {
        const userName = await localStorage.getItem('userName');
        const password = await localStorage.getItem('password');
        const response = await signin(userName, password);
        token = response.data.jwt;
        await localStorage.setItem('jwtToken', token);
    }
    return token;
};

export const getAddresses = async () => {
    try {
        const token = await getAuthToken();
        const response = await axios.get(`${config.API_URL}/addresses`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                populate: '*',
            },
        });
        return response.data.data;
    } catch (error) {
        console.error("Errore nel caricamento degli indirizzi:", error);
        throw error;
    }
};

export const createAddress = async (addressData) => {
    try {
        const token = await getAuthToken();
        const response = await axios.post(`${config.API_URL}/addresses`, {
            data: addressData,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data; // Restituisce l'indirizzo creato
    } catch (error) {
        console.error("Errore nella creazione dell'indirizzo:", error);
        throw error;
    }
};

