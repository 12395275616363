import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { logout } from '../services/authService'; 
import { getRestaurantByUserId } from '../services/restaurantService'; 

const DashboardLayout = () => {
  const [restaurantName, setRestaurantName] = useState('NomeRistorante');
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRestaurantName = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const restaurant = await getRestaurantByUserId(userId);

        if (restaurant && restaurant.attributes.Name) {
          setRestaurantName(restaurant.attributes.Name);
        } else {
          setRestaurantName('Nome del ristorante non disponibile');
        }
      } catch (error) {
        console.error('Errore durante il recupero del nome del ristorante:', error);
        setRestaurantName('Errore nel recupero del nome del ristorante');
      }
    };

    fetchRestaurantName();
  }, []);

  const openLogoutConfirm = () => {
    setShowLogoutConfirm(true);
  };

  const closeLogoutConfirm = () => {
    setShowLogoutConfirm(false);
  };

  const handleLogout = async () => {
    await logout();
    closeLogoutConfirm();
    navigate('/login');
  };

  return (
    <div className="d-flex flex-column">
      <Header restaurantName={restaurantName} />
      <div className="d-flex">
        <Sidebar openLogoutConfirm={openLogoutConfirm} />
        <main className="flex-grow-1 p-4">
          <Outlet />
        </main>
      </div>

      {showLogoutConfirm && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Conferma Logout</h5>
                <button type="button" className="close" onClick={closeLogoutConfirm}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Sei sicuro di voler effettuare il logout?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeLogoutConfirm}>Annulla</button>
                <button type="button" className="btn btn-danger" onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardLayout;

