import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { signin } from './authService';
import config from '../config';

const getValidToken = async () => {
    let token = await localStorage.getItem('jwtToken');

    if (!token) {
        throw new Error('Token JWT non trovato');
    }

    const decodedToken = jwtDecode(token);

    if (decodedToken.exp * 1000 < Date.now()) {
        const userName = await localStorage.getItem('userName');
        const password = await localStorage.getItem('password');

        if (userName && password) {
            const response = await signin(userName, password);
            token = response.data.jwt;
            await localStorage.setItem('jwtToken', token);
        } else {
            throw new Error('Credenziali non trovate, eseguire nuovamente l\'accesso.');
        }
    }

    return token;
};

export const getRestaurantByUserId = async (userId) => {
    try {
        const token = await getValidToken();

        const response = await axios.get(`${config.API_URL}/restaurants`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            params: {
                filters: {
                    users: {
                        id: {
                            $eq: userId,
                        },
                    },
                },
                populate: '*',
            },
        });

        return response.data.data[0];  // Restituisce il primo ristorante trovato per l'utente
    } catch (error) {
        console.error('Errore durante la richiesta API:', error);
        throw error;
    }
};

export const deleteMedia = async (mediaId) => {
    try {
        const token = await getValidToken();

        await axios.delete(`${config.API_URL}/upload/files/${mediaId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Errore durante l\'eliminazione del file:', error);
        throw error;
    }
};

export const uploadMedia = async (file) => {
    try {
        const token = await getValidToken();

        const formData = new FormData();
        formData.append('files', file);

        const response = await axios.post(`${config.API_URL}/upload`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data[0]; // Restituisce il file caricato
    } catch (error) {
        console.error('Errore durante il caricamento del file:', error);
        throw error;
    }
};

export const updateRestaurant = async (restaurantId, restaurantData, mediaFiles = {}) => {
    try {
        const token = await getValidToken();

        // Step 1: Elimina l'immagine esistente se è presente e se è stata caricata una nuova immagine
        if (mediaFiles.image && restaurantData.image?.id) {
            await deleteMedia(restaurantData.image.id);
            restaurantData.image = null;
        }

        if (mediaFiles.copertina && restaurantData.copertina?.id) {
            await deleteMedia(restaurantData.copertina.id);
            restaurantData.copertina = null;
        }

        // Step 2: Carica nuove immagini, se presenti
        if (mediaFiles.image) {
            const uploadedImage = await uploadMedia(mediaFiles.image);
            restaurantData.image = uploadedImage.id;
        }

        if (mediaFiles.copertina) {
            const uploadedCopertina = await uploadMedia(mediaFiles.copertina);
            restaurantData.copertina = uploadedCopertina.id;
        }

        // Step 3: Rimuovi campi non modificati dal payload
        const payload = { ...restaurantData };
        if (!mediaFiles.image) delete payload.image;
        if (!mediaFiles.copertina) delete payload.copertina;

        // Step 4: Esegui la richiesta PUT per aggiornare il ristorante
        const response = await axios.put(`${config.API_URL}/restaurants/${restaurantId}`, {
            data: payload,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Errore durante la richiesta API:', error);
        throw error;
    }
};


