import React, { useState, useEffect } from 'react';
import { getOrdersInAttesaConferma, getOrdersInCorso, getOrdersInPreparazione, getOrdersInConsegna, getOrdersPassati, updateOrderStatus } from '../services/orderService';

const OrderList = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [filter, setFilter] = useState('Tutti'); // Stato per il filtro
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState({});

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const attesaConferma = await getOrdersInAttesaConferma();
        const inCorso = await getOrdersInCorso();
        const inPreparazione = await getOrdersInPreparazione();
        const inConsegna = await getOrdersInConsegna();
        const passati = await getOrdersPassati();
        setAllOrders([...attesaConferma, ...inCorso, ...inPreparazione, ...inConsegna, ...passati]);
      } catch (error) {
        console.error('Errore nel recupero degli ordini:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleStatusChange = (orderId, status) => {
    setSelectedStatus((prevState) => ({
      ...prevState,
      [orderId]: status,
    }));
  };

  const handleUpdateStatus = async (orderId) => {
    const status = selectedStatus[orderId];
    if (status) {
      try {
        await updateOrderStatus(orderId, status);
        window.location.reload(); // Ricarica la pagina dopo l'aggiornamento dello stato
      } catch (error) {
        console.error('Errore durante l\'aggiornamento dello stato:', error);
      }
    }
  };

  if (loading) {
    return <p>Caricamento ordini in corso...</p>;
  }

  const filteredOrders = filter === 'Tutti'
    ? allOrders
    : allOrders.filter(order => order.attributes.Status === filter);

  const orderSections = {
    'In Attesa di Conferma': filteredOrders.filter(order => order.attributes.Status === 'In attesa di conferma'),
    'In Corso': filteredOrders.filter(order => order.attributes.Status === 'In Corso'),
    'In Preparazione': filteredOrders.filter(order => order.attributes.Status === 'In Preparazione'),
    'In Consegna': filteredOrders.filter(order => order.attributes.Status === 'In Consegna'),
    'Ordini Passati': filteredOrders.filter(order => 
      order.attributes.Status === 'Completato' || 
      order.attributes.Status === 'Annullato'
    ),
  };

  return (
    <div className="container">
      <h2 className="my-4">Ordini</h2>
      
      {/* Filtro */}
      <div className="mb-4">
        <label htmlFor="filter" className="form-label">Filtra per stato:</label>
        <select id="filter" className="form-select" value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="Tutti">Tutti</option>
          <option value="In attesa di conferma">In Attesa di Conferma</option>
          <option value="In Corso">In Corso</option>
          <option value="In Preparazione">In Preparazione</option>
          <option value="In Consegna">In Consegna</option>
          <option value="Completato">Completato</option>
          <option value="Annullato">Annullato</option>
        </select>
      </div>
      
      {/* Sezioni Ordini */}
      {Object.keys(orderSections).map(section => (
        <div key={section} className="mb-4">
          <h3>{section}</h3>
          {orderSections[section].length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Stato</th>
                    <th scope="col">Cliente</th>
                    <th scope="col">Indirizzo</th>
                    <th scope="col">Totale</th>
                    <th scope="col">Aggiorna Stato</th>
                  </tr>
                </thead>
                <tbody>
                  {orderSections[section].map(order => (
                    <tr key={order.id}>
                      <td>{order.id}</td>
                      <td>{order.attributes.Status}</td>
                      <td>{order.attributes.user?.data?.attributes?.username || 'N/A'}</td>
                      <td>
                        {order.attributes.user?.data?.attributes?.addresses?.data[0]?.attributes?.Street || 'N/A'}, 
                        {order.attributes.user?.data?.attributes?.addresses?.data[0]?.attributes?.City || 'N/A'}
                      </td>
                      <td>${order.attributes.totalPrice}</td>
                      <td>
                        <select
                          value={selectedStatus[order.id] || order.attributes.Status}
                          onChange={(e) => handleStatusChange(order.id, e.target.value)}
                        >
                          <option value="In attesa di conferma">In Attesa di Conferma</option>
                          <option value="In Corso">In Corso</option>
                          <option value="In Preparazione">In Preparazione</option>
                          <option value="In Consegna">In Consegna</option>
                          <option value="Completato">Completato</option>
                          <option value="Annullato">Annullato</option>
                        </select>
                        <button 
                          className="btn btn-primary ml-2"
                          onClick={() => handleUpdateStatus(order.id)}
                        >
                          Aggiorna
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Nessun ordine in questa sezione.</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderList;

