import React, { useEffect, useState } from 'react';
import { getRestaurantByUserId, updateRestaurant } from '../services/restaurantService';
import { getAddresses, createAddress } from '../services/addressService';
import { getShopTypes } from '../services/shopService';
import config from '../config';

const Settings = () => {
    const [restaurantData, setRestaurantData] = useState({
        id: null,
        Name: '',
        description: '',
        phoneNumber: '',
        openingHours: '',
        isAvailable: true,
        isVacation: false,
        image: null,
        copertina: null,
        address: null,
        shop_types: [], // Aggiunto per gestire i tipi di negozio
    });
    const [addresses, setAddresses] = useState([]);
    const [shopTypes, setShopTypes] = useState([]); // Stato per i tipi di negozio
    const [loading, setLoading] = useState(true);
    const userId = localStorage.getItem('userId');
    const [creatingAddress, setCreatingAddress] = useState(false);
    const [newAddress, setNewAddress] = useState({
        Street: '',
        City: '',
        PostalCode: '',
        Country: 'Italia',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [restaurant, addresses, shopTypes] = await Promise.all([
                    getRestaurantByUserId(userId),
                    getAddresses(),
                    getShopTypes()
                ]);

                if (restaurant) {
                    setRestaurantData({
                        ...restaurant.attributes,
                        id: restaurant.id,
                        image: restaurant.attributes.image?.data?.attributes?.url || null,
                        copertina: restaurant.attributes.copertina?.data?.attributes?.url || null,
                        address: restaurant.attributes.address?.data?.id || null,
                        shop_types: restaurant.attributes.shop_types?.data.map(st => st.id) || [], // Recupera i tipi di negozio esistenti
                    });
                }
                setAddresses(addresses);
                setShopTypes(shopTypes);
                setLoading(false);
            } catch (error) {
                console.error('Errore nel caricamento dei dati', error);
            }
        };

        fetchData();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setRestaurantData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setRestaurantData((prevState) => ({
            ...prevState,
            [name]: files[0],
        }));
    };

    const handleShopTypeChange = (e) => {
        const { value, checked } = e.target;
        setRestaurantData((prevState) => {
            const selectedShopTypes = new Set(prevState.shop_types);
            if (checked) {
                selectedShopTypes.add(parseInt(value));
            } else {
                selectedShopTypes.delete(parseInt(value));
            }
            return { ...prevState, shop_types: Array.from(selectedShopTypes) };
        });
    };

    const handleCreateAddress = async () => {
        try {
            const newAddr = await createAddress(newAddress);
            setAddresses([...addresses, newAddr]);
            setRestaurantData((prevState) => ({
                ...prevState,
                address: newAddr.id,
            }));
            setCreatingAddress(false);
        } catch (error) {
            console.error('Errore durante la creazione dell\'indirizzo:', error);
        }
    };

    const handleSave = async () => {
        try {
            if (!restaurantData.id) {
                throw new Error("ID del ristorante non definito");
            }

            const mediaFiles = {};
            if (restaurantData.image instanceof File) {
                mediaFiles.image = restaurantData.image;
            }
            if (restaurantData.copertina instanceof File) {
                mediaFiles.copertina = restaurantData.copertina;
            }

            const updatedData = { ...restaurantData };
            if (restaurantData.image instanceof File) delete updatedData.image;
            if (restaurantData.copertina instanceof File) delete updatedData.copertina;

            await updateRestaurant(restaurantData.id, updatedData, mediaFiles);
            alert('Impostazioni aggiornate con successo!');
        } catch (error) {
            console.error('Errore durante l\'aggiornamento delle impostazioni', error);
            alert('Si è verificato un errore durante l\'aggiornamento delle impostazioni.');
        }
    };

        if (loading) return <p>Caricamento...</p>;

return (
    <div className="container">
        <h2>Impostazioni del Ristorante</h2>
        {/* Gestione del logo */}
        <div className="mb-3">
            <label className="form-label">Logo</label>
            <input
                type="file"
                name="image"
                className="form-control"
                onChange={handleFileChange}
            />
            {restaurantData.image && !(restaurantData.image instanceof File) && (
                <div className="mt-2">
                    <img
                        src={`${config.API_URL.replace('/api', '')}${restaurantData.image}`}
                        alt="Logo attuale"
                        style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover', border: '1px solid #ddd', padding: '5px' }}
                    />
                </div>
            )}
        </div>
        {/* Gestione della copertina */}
        <div className="mb-3">
            <label className="form-label">Copertina</label>
            <input
                type="file"
                name="copertina"
                className="form-control"
                onChange={handleFileChange}
            />
            {restaurantData.copertina && !(restaurantData.copertina instanceof File) && (
                <div className="mt-2">
                    <img
                        src={`${config.API_URL.replace('/api', '')}${restaurantData.copertina}`}
                        alt="Copertina attuale"
                        style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover', border: '1px solid #ddd', padding: '5px' }}
                    />
                </div>
            )}
        </div>
        {/* Selezione dell'indirizzo */}
        <div className="mb-3">
            <label className="form-label">Indirizzo</label>
            <select
                name="address"
                className="form-control"
                value={restaurantData.address || ''}
                onChange={handleChange}
            >
                <option value="" disabled>Seleziona un indirizzo</option>
                {addresses.map((address) => (
                    <option key={address.id} value={address.id}>
                        {`${address.attributes.Street}, ${address.attributes.City}, ${address.attributes.Country}`}
                    </option>
                ))}
            </select>
            <button type="button" className="btn btn-secondary mt-2" onClick={() => setCreatingAddress(true)}>
                Crea nuovo indirizzo
            </button>
            {creatingAddress && (
                <div className="mt-3">
                    <input
                        type="text"
                        name="Street"
                        placeholder="Via"
                        className="form-control mb-2"
                        value={newAddress.Street}
                        onChange={(e) => setNewAddress({ ...newAddress, Street: e.target.value })}
                    />
                    <input
                        type="text"
                        name="City"
                        placeholder="Città"
                        className="form-control mb-2"
                        value={newAddress.City}
                        onChange={(e) => setNewAddress({ ...newAddress, City: e.target.value })}
                    />
                    <input
                        type="text"
                        name="PostalCode"
                        placeholder="CAP"
                        className="form-control mb-2"
                        value={newAddress.PostalCode}
                        onChange={(e) => setNewAddress({ ...newAddress, PostalCode: e.target.value })}
                    />
                    <input
                        type="text"
                        name="Country"
                        placeholder="Paese"
                        className="form-control mb-2"
                        value={newAddress.Country}
                        onChange={(e) => setNewAddress({ ...newAddress, Country: e.target.value })}
                    />
                    <button type="button" className="btn btn-primary" onClick={handleCreateAddress}>
                        Salva nuovo indirizzo
                    </button>
                </div>
            )}
        </div>
        {/* Selezione dei tipi di negozio */}
        <div className="mb-3">
            <label className="form-label">Tipi di Negozio</label>
            {shopTypes.map((shopType) => (
                <div key={shopType.id} className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={shopType.id}
                        checked={restaurantData.shop_types.includes(shopType.id)}
                        onChange={handleShopTypeChange}
                    />
                    <label className="form-check-label">
                        {shopType.attributes.name}
                    </label>
                </div>
            ))}
        </div>
        {/* Altri campi del ristorante */}
        <div className="mb-3">
            <label className="form-label">Nome del Ristorante</label>
            <input
                type="text"
                name="Name"
                className="form-control"
                value={restaurantData.Name || ''}
                onChange={handleChange}
                required
            />
        </div>
        <div className="mb-3">
            <label className="form-label">Descrizione</label>
            <textarea
                name="description"
                className="form-control"
                value={restaurantData.description || ''}
                onChange={handleChange}
            />
        </div>
        <div className="mb-3">
            <label className="form-label">Numero di Telefono</label>
            <input
                type="text"
                name="phoneNumber"
                className="form-control"
                value={restaurantData.phoneNumber || ''}
                onChange={handleChange}
            />
        </div>
        <div className="mb-3">
            <label className="form-label">Orari di Apertura (JSON)</label>
            <textarea
                name="openingHours"
                className="form-control"
                value={JSON.stringify(restaurantData.openingHours, null, 2) || ''}
                onChange={(e) => handleChange({ target: { name: 'openingHours', value: JSON.parse(e.target.value) } })}
            />
        </div>
        <div className="form-check">
            <input
                type="checkbox"
                name="isAvailable"
                className="form-check-input"
                checked={restaurantData.isAvailable}
                onChange={handleChange}
            />
            <label className="form-check-label">Disponibile</label>
        </div>
        <div className="form-check">
            <input
                type="checkbox"
                name="isVacation"
                className="form-check-input"
                checked={restaurantData.isVacation}
                onChange={handleChange}
            />
            <label className="form-check-label">In Vacanza</label>
        </div>
        <button type="button" className="btn btn-primary mt-2" onClick={handleSave}>Salva</button>
    </div>
);

};

export default Settings;

