import React, { useState } from 'react';
import { signin } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.svg';

const LoginForm = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Stato per gestire gli errori
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Resetta l'errore prima di ogni tentativo
    try {
      await signin(identifier, password);
      navigate('/dashboard'); // Reindirizzamento alla dashboard
    } catch (error) {
      console.error('Login fallito', error);
      setError(error.message); // Imposta l'errore per visualizzarlo nell'interfaccia
    }
  };

  return (
    <div className="container d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <div className="card shadow-lg" style={{ maxWidth: '400px', width: '100%' }}>
        <div className="card-body text-center">
          <img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '20px' }} />
          <h3 className="card-title mb-4">Login</h3>
          <form onSubmit={handleSubmit}>
            {error && <div className="alert alert-danger">{error}</div>} {/* Mostra l'errore se presente */}
            <div className="mb-3">
              <label htmlFor="identifier" className="form-label">Email o Nome Utente</label>
              <input 
                type="text" 
                className="form-control" 
                id="identifier" 
                value={identifier} 
                onChange={(e) => setIdentifier(e.target.value)} 
                required 
                placeholder="Inserisci email o nome utente"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input 
                type="password" 
                className="form-control" 
                id="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
                placeholder="Inserisci la password"
              />
            </div>
            <div className="d-grid">
              <button type="submit" className="btn btn-primary btn-block">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

