import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Sidebar = ({ openLogoutConfirm }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="d-flex">
      <div
        className={`sidebar bg-dark text-white ${isOpen ? 'active' : ''}`}
        style={{
          width: isOpen ? '220px' : '60px',  // Ridotto da 250px a 220px
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          transition: 'width 0.3s ease',
          zIndex: 1000,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ padding: '10px', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <button 
            className="btn btn-sm btn-light" 
            onClick={toggleSidebar}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              height: '40px',
            }}
          >
            <i className={isOpen ? "fas fa-times" : "fas fa-bars"} style={{ fontSize: '1.2rem' }}></i> 
          </button>
        </div>
        {isOpen && (
          <>
            <ul className="nav flex-column mt-2">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link text-white">
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/dashboard/orders" className="nav-link text-white">
                  Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/dashboard/menu" className="nav-link text-white">
                  Menu
                </Link>
              </li>
            </ul>
            <div className="mt-auto p-3">
              <Link to="/dashboard/settings" className="btn btn-secondary w-100 mb-2" style={{ padding: '10px 0' }}>
                <i className="fas fa-cog mr-2"></i> Impostazioni
              </Link>
              <button 
                className="btn btn-danger w-100" 
                onClick={openLogoutConfirm}
                style={{ padding: '10px 0' }}
              >
                <i className="fas fa-sign-out-alt mr-2"></i> Logout
              </button>
            </div>
          </>
        )}
      </div>

      <div
        className="content flex-grow-1"
        style={{
          marginLeft: isOpen ? '220px' : '60px',  // Ridotto da 250px a 220px
          transition: 'margin-left 0.3s ease',
          width: '100%',
        }}
      >
        <div className="container mt-3">
          {/* Placeholder per i contenuti principali */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

