import axios from 'axios';
import config from '../config';
import jwtDecode from 'jwt-decode';

// Funzione per ottenere il token di autenticazione
const getAuthToken = async () => {
    const token = await localStorage.getItem('jwtToken');
    if (!token) {
        throw new Error('Token non trovato');
    }
    return token;
};

// Recupera ordini in attesa di conferma
export const getOrdersInAttesaConferma = async () => {
    try {
        const token = await getAuthToken();
        const restaurantId = localStorage.getItem('restaurantId');

        const response = await axios.get(
            `${config.API_URL}/orders?filters[restaurant][id][$eq]=${restaurantId}&filters[Status][$eq]=In attesa di conferma&populate[user]=*&populate[user.addresses]=*`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.error("Errore nel recupero degli ordini in attesa di conferma:", error);
        return [];
    }
};

// Recupera ordini in corso
export const getOrdersInCorso = async () => {
    try {
        const token = await getAuthToken();
        const restaurantId = localStorage.getItem('restaurantId');

        const response = await axios.get(
            `${config.API_URL}/orders?filters[restaurant][id][$eq]=${restaurantId}&filters[Status][$eq]=In Corso&populate[user]=*&populate[user.addresses]=*`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.error("Errore nel recupero degli ordini in corso:", error);
        return [];
    }
};

// Recupera ordini in preparazione
export const getOrdersInPreparazione = async () => {
    try {
        const token = await getAuthToken();
        const restaurantId = localStorage.getItem('restaurantId');

        const response = await axios.get(
            `${config.API_URL}/orders?filters[restaurant][id][$eq]=${restaurantId}&filters[Status][$eq]=In Preparazione&populate[user]=*&populate[user.addresses]=*`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.error("Errore nel recupero degli ordini in preparazione:", error);
        return [];
    }
};

// Recupera ordini in consegna
export const getOrdersInConsegna = async () => {
    try {
        const token = await getAuthToken();
        const restaurantId = localStorage.getItem('restaurantId');

        const response = await axios.get(
            `${config.API_URL}/orders?filters[restaurant][id][$eq]=${restaurantId}&filters[Status][$eq]=In Consegna&populate[user]=*&populate[user.addresses]=*`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.error("Errore nel recupero degli ordini in consegna:", error);
        return [];
    }
};

// Recupera ordini passati (Completato e Annullato)
export const getOrdersPassati = async () => {
    try {
        const token = await getAuthToken();
        const restaurantId = localStorage.getItem('restaurantId');

        const response = await axios.get(
            `${config.API_URL}/orders?filters[restaurant][id][$eq]=${restaurantId}&filters[Status][$in][0]=Completato&filters[Status][$in][1]=Annullato&populate[user]=*&populate[user.addresses]=*`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.error("Errore nel recupero degli ordini passati:", error);
        return [];
    }
};

// Funzione per aggiornare lo stato di un ordine
export const updateOrderStatus = async (orderId, newStatus) => {
    try {
        const token = await getAuthToken();

        const response = await axios.put(
            `${config.API_URL}/orders/${orderId}`,
            {
                data: {
                    Status: newStatus
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.error("Errore durante l'aggiornamento dello stato dell'ordine:", error);
        throw error;
    }
};

